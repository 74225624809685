.App {
  text-align: center;
}

.todo.completed {
  text-decoration: line-through;
  color: red;
}

button {
  background: #9999FF;
  border-radius: 10px;
  padding: 8px 24px;
  margin: 10px;
  border: 1px solid grey;
}

button:hover {
  background: #fff;
  color: #9999FF;
  font-weight: bold;
}

.todolist {
  width: 60%;
  max-height: 600px;
  margin: 0 auto;
  background: #eaeaff;
  padding: 20px 0;
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
}

.todo {
  border: 1px solid #20B2AA;
  width: 40%;
  margin: 10px;
  background: #fff;
  font-size: 1.2rem;
}

input {
  width: 30%;
  padding: 8px 0;
  font-size: 1.2rem;
}